<template>
    <div v-if="!loader"> 
      <b-card v-if="$store.state.app.language == 'en'">
        <h3>{{ $t("referral_program") }}</h3><br>
    Dear <strong>{{company.main_contact.name}} {{ company.main_contact.last_name }}, </strong> <br><br>
    We are excited to introduce the Aequitex Referral Program, designed exclusively for you. This program is our way of expressing gratitude for your trust and support while giving you the opportunity to benefit from your Aequitex experience even more.    <br><br>
    <strong>How Does It Work?</strong><br>
    <strong>Register and Share: </strong> To get started, simply create your individual referral link by clicking the button below and share it with your friends, acquaintances, or business partners.<br>
    <strong>Successful Referrals:</strong> Only when new community member register through this individual referral link, they can be uniquely assigned to you. When the new member verifies their identity and successfully sells an invoice, you will earn your reward.<br><br>
     <strong>What's in it for You?</strong>
    Depending on the number of successful referrals, you can enjoy substantial discounts on your annual platform fee for the next billing cycle. The more referrals, the bigger the rewards: <br><br>
  <ul>
  <li>25% discount if one new SME member registers and completes a successful invoice sale before the next billing cycle.</li> 
   <li>50% discount if two new SME members register and complete successful invoice sales before the next billing cycle.</li>
   <li>75% discount if three new SME members register and complete successful invoice sales before the next billing cycle.</li>
   <li>100% discount if four new SME members register and complete successful invoice sales before the next billing cycle. </li>
  </ul>
  This program not only allows you to save on your platform fees, but also the new member recruited by you will get 25% off on the next billing of the annual fee after he successfully sells his first invoice through Aequitex.<br><br>
  Join Us in Growing Together... Aequitex is committed to supporting SMEs like yours by providing a fast, fair, and transparent access to liquidity. With the Referral Rewards Program, we are taking this commitment to the next level, allowing you to actively participate in our mission.<br><br>
  Let's grow together, share the benefits, and help more SMEs thrive with Aequitex.<br><br>
  Are you ready to start earning rewards? Click the button below to generate your unique referral link and get started!

   </b-card>  
   <b-card v-else-if="$store.state.app.language == 'de'">
    <h3>{{ $t("referral_program") }}</h3><br>
    Sehr geehrte/r <strong>{{company.main_contact.name}} {{ company.main_contact.last_name }}, </strong> <br><br>
    Wir freuen uns, Ihnen das Aequitex Empfehlungsprogramm vorstellen zu dürfen. Dieses Programm ist unsere Art, Ihnen für Ihr Vertrauen und Ihre Unterstützung zu danken und Ihnen gleichzeitig die Möglichkeit zu bieten, noch mehr von Ihrer Aequitex-Erfahrung zu profitieren. <br><br>
    <strong>Wie funktioniert das Ganze?</strong><br>
    <strong>Registrieren und Teilen: </strong> Um loszulegen, erstellen Sie einfach Ihren individuellen Empfehlungslink, indem Sie auf die Schaltfläche unten klicken, und teilen Sie ihn mit Ihren Freunden, Bekannten oder Geschäftspartnern.<br>
    <strong>Erfolgreiche Empfehlungen:</strong> Nur wenn sich neue Community-Mitglieder über diesen individuellen Empfehlungslink registrieren, können sie eindeutig Ihnen zugeordnet werden. Wenn das neue Mitglied seine Identität verifiziert und erfolgreich eine Rechnung verkauft hat, erhalten Sie Ihre Belohnung.<br><br>
     <strong>Was haben Sie davon?</strong>
     Je nach Anzahl der erfolgreichen Empfehlungen können Sie erhebliche Rabatte auf Ihre jährliche Plattformgebühr für den nächsten Abrechnungszeitraum erhalten. Je mehr Empfehlungen, desto größer die Belohnungen: <br><br>
  <ul>
  <li> 25% Rabatt, wenn sich ein neues KMU-Mitglied vor dem nächsten Abrechnungszeitraum registriert und eine erfolgreiche Rechnungsverkaufsabschluss vorweist.</li> 
   <li> 50% Rabatt, wenn sich zwei neue KMU-Mitglieder vor dem nächsten Abrechnungszeitraum registrieren und erfolgreiche Rechnungsverkaufsabschlüsse vorweisen.</li>
   <li>75% Rabatt, wenn sich drei neue KMU-Mitglieder vor dem nächsten Abrechnungszeitraum registrieren und erfolgreiche Rechnungsverkaufsabschlüsse vorweisen.</li>
   <li>100% Rabatt, wenn sich vier neue KMU-Mitglieder vor dem nächsten Abrechnungszeitraum registrieren und erfolgreiche Rechnungsverkaufsabschlüsse vorweisen.</li>
  </ul>
  Dieses Programm ermöglicht es Ihnen nicht nur, bei Ihren Plattformgebühren zu sparen, sondern auch dem von Ihnen angeworbenen neuen Mitglied wird ein Rabatt von 25% auf die nächste anstehende Verrechnung der Jahresgebühr gewährt, nachdem es erfolgreich seine erste Rechnung über Aequitex verkauft hat.<br><br>
  Schließen Sie sich uns an, um gemeinsam zu wachsen. Aequitex möchte seinen Community-Mitgliedern einen schnellen, fairen und transparenten Zugang zu Liquidität bieten. Mit dem Empfehlungsprogramm bringen wir dieses Engagement auf die nächste Stufe und ermöglichen es Ihnen, aktiv an unserer Mission teilzunehmen.<br><br>
  Lassen Sie uns gemeinsam wachsen, die Vorteile teilen und dazu beitragen, dass noch mehr KMUs durch den Service der Aequitex-Plattform wachsen können.<br><br>
   Klicken Sie auf die Schaltfläche unten, um Ihren individuellen Empfehlungslink zu generieren und loszulegen!<br><br>


   </b-card> 
   <b-card>
    <b-row m-0 p-0>
      <b-col cols="12" md="6">
        <b-row m-0 p-0>
       <b-col>  
        <span class="pt-2"><h4>{{ $t("referral_program") }} </h4></span>
      </b-col>
        
        
        <b-col class="justify-content-end" align="end"> <b-button class=" mb-1" size="sm" v-if="code==null"   @click="generateLink()">{{ $t("generate_link") }}</b-button></b-col>
    </b-row>
    <b-row m-0 p-0>
        <b-col cols="12" class="d-flex" >   
      <strong>{{ $t("code") }}</strong> : <p class="ml-1">{{ referalData ? referalData.rrp_link : code }}
        <strong> <b-button size="sm" variant="light"><feather-icon v-if="code || (referalData && referalData.rrp_link) "
              @click="copyToClipboard"
              id="copyButton"
              ref="copyButton"
              role="button"
              icon="CopyIcon"
              class="primary"
              size="10"
            />
            <b-tooltip  target="copyButton" triggers="click"
            :show.sync="showCopiedTooltip"
            placement="top">
              Copied!
            </b-tooltip></b-button></strong>   <br>
           <small>{{ $t("copy_and_share_link") }}</small> 
       </p>
       </b-col>
    </b-row>
      
    <b-row m-0 p-0>
        <b-col cols="12"  md="6" class="d-flex" >   
      <strong>{{ $t("registered_companies") }}</strong> : <p class="ml-1">{{ companies ? companies.length : '0' }}   
        </p>
       </b-col>
    </b-row>
    <b-row m-0 p-0>
        <b-col cols="12" class="d-flex" >   
      <strong>{{ $t("discount_available") }}</strong> : <p class="ml-1">{{ discount > 0 ? discount*100 +'%' : '0%' }}   
        </p>
       </b-col>
    </b-row>
      </b-col>
    </b-row>

 </b-card>
 <b-card v-if="companies">
    <b-table hover :items="companies" :fields="fields" responsive>
              <template #head()="scope">
                <div class="text-nowrap">{{ $t(scope.label) }}</div>
              </template>
              <template #cell(name)="data">
                <span>{{ data.value ? data.value : '-'}}</span>
              </template>
              <template #cell(id)="data">
                <span style="width:55px;" class="badge badge-light-secondary">U-{{data.value}}</span>
              </template>
              <template #cell(invoices_sold)="data">
                <span>{{data.value}}</span>
              </template>
              <template #cell(verified)="data">
                <span v-if="data.item.verified == 0">{{$t("referal_clicked_link_not_verified")}}</span>
                <span v-if="data.item.verified == 1 && data.item.invoices_sold == 0">{{$t("referal_vierified_no_invoices")}}</span>
                <span v-if="data.item.verified == 1 && data.item.invoices_sold > 0">{{$t("recommendation_successful")}}</span>


              </template>
              <template #cell(discount)="data">
                <span v-if="data.item.verified == 1 && data.item.invoices_sold >0">{{data.value}}%</span>
                <span v-else>0%</span>

              </template>
            
            </b-table>
 </b-card>
    </div>
   <div v-else>  <img src="/new-loader.svg" /></div>
  </template>
  <script src="https://cdnjs.cloudflare.com/ajax/libs/clipboard.js/2.0.8/clipboard.min.js"></script>

  <script>
  import { ValidationProvider, ValidationObserver } from "vee-validate";
  import ToastificationContent from "@core/components/toastification/ToastificationContent";
  import ClipboardJS from 'clipboard';

  export default {
   name: 'referralProgram',
   components: {
    ValidationProvider,
    ValidationObserver,
  },
   data() {
    return {
        billing: null,
        company: null,
        loader: false,
        preregistered: false,
        referalData: null,
        companies: [],
        discount: null,
        code: null,
        fields: [
        {
          label: "user_id",
          align: "start",
          key: "user_id",
        },
        {
          label: "company_name",
          align: "start",
          key: "company_name",
        },
        // {
        //   label: "invoices_sold",
        //   align: "start",
        //   key: "invoices_sold",
        // },
        // {
        //   label: "discount",
        //   align: "start",
        //   key: "discount",
        // },
        {
          label: "status",
          align: "start",
          key: "verified",
        },
      ],
      showCopiedTooltip: false,
    }
   },
   created(){
    this.getInfo();
    this.getCode();
    this.getCompaniesList();
    this.getBilling();
   },
   methods:{
    getInfo() {
        this.loader = true;
      this.$http
        .post(`/company/show/${this.$store.state.auth.user.company_id}`)
        .then((res) => {
            if(res){
                this.loader = false;
              this.company = res.data.value;
              this.$http
          .post("/get-company-data-from-zefix", {
            search: res.data.value.uid
          })
          .then((res) => {
            if (res.data.value) {
             this.preregistered = res.data.value.preregistered;
            }
          });
            }
        });
    },
    getCode(){
        this.$http
        .post(`/company/referral/getCode`)
        .then((res) => {
            if(res && res.data && res.data.value){
              this.code = res.data.value.code;
              this.discount = res.data.value.discount;
            }
        });
    },
    generateLink(){
        this.$http
        .post(`/company/referral/generateRRP`)
        .then((res) => {
            if(res){
                this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "SuccessIcon",
                variant: "success",
              },
            });
              this.referalData = res.data.value;
              
            }
        });
    },
    getCompaniesList(){
        this.$http
        .post(`/company/referral/getCompanies`)
        .then((res) => {
            if(res){
              this.companies = res.data.value;
            }
        });
    },
    getBilling(){
        this.$http
        .post(`/company/billing/get`, {company_id: this.$store.state.auth.user.company_id})
        .then((res) => {
            if(res){
             this.billing = res.data.value[0];
            }
        });
    },
    copyToClipboard() {
  
      const codeToCopy = this.referalData ? this.referalData.rrp_link : this.code;
      const clipboard = new ClipboardJS('.primary', {
        text: () => `terminal.aequitex.com/register?affiliate_code=${codeToCopy}`,
      });
      clipboard.on("success", () => {
        this.showCopiedTooltip = true;
        setTimeout(() => {
          this.showCopiedTooltip = false;
        }, 1000);
        console.log("Code copied to clipboard");
    
        clipboard.destroy();
      });
      clipboard.on("error", () => {
        console.error("Failed to copy to clipboard");
        clipboard.destroy();
      });
    },

   }
  };
  </script>
  
  <style scoped>
  </style>
  